import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Navbar from '../../components/MainAppBar';
import Footer from '../../components/Footer';
import ErrorAlertBoundary from '../../components/ErrorAlertBoundary';
import { Toaster } from 'react-hot-toast';
import { withAudience } from '~/hooks/audience';

const useStyles = makeStyles(theme => ({
  content: {},
  container: {
    backgroundColor: '#f2f2f2'
  },
  footer: {}
}));

const Main = ({
  children,
  lang,
  navbarContent,
  endNavbarContent,
  navbarClassName,
  containerClassName,
  showLanguageMenu,
  showSignUp,
  showLogin,
  signUpLabel,
  showFooter,
  showUserMenu,
  showNotificationPanel,
  showTutorials,
  audience,
  showSmallFooter,
  showLinks
}) => {
  const classes = useStyles();

  return (
    <ErrorAlertBoundary>
      <Box className={clsx(classes.container, containerClassName)}>
        <Toaster position="top-right" reverseOrder={false} toastOptions={{ duration: 6000 }} />
        <Navbar
          lang={lang}
          className={navbarClassName || ''}
          showSignUp={showSignUp}
          showLogin={showLogin}
          showLanguageMenu={showLanguageMenu}
          signUpLabel={signUpLabel}
          showUserMenu={showUserMenu}
          showNotificationPanel={showNotificationPanel}
          showTutorials={showTutorials}
          endContent={endNavbarContent}
          showLinks={showLinks}
        >
          {navbarContent}
        </Navbar>
        <Box className={classes.content} id="woxo-main-container">
          {children}
        </Box>
        {showFooter && <Footer className={classes.footer} showSmallFooter={showSmallFooter} />}
      </Box>
    </ErrorAlertBoundary>
  );
};

Main.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  showFooter: PropTypes.bool,
  lang: PropTypes.oneOf(['', 'pt', 'es']),
  navbarContent: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  endNavbarContent: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  navbarClassName: PropTypes.string,
  showSignUp: PropTypes.bool,
  showLogin: PropTypes.bool,
  showLanguageMenu: PropTypes.bool,
  showUserMenu: PropTypes.bool,
  signUpLabel: PropTypes.string,
  containerClassName: PropTypes.string,
  showNotificationPanel: PropTypes.bool,
  showTutorials: PropTypes.bool,
  audience: PropTypes.string,
  showSmallFooter: PropTypes.bool,
  showLinks: PropTypes.bool
};

Main.defaultProps = {
  children: null,
  lang: '',
  navbarContent: null,
  endNavbarContent: null,
  navbarClassName: null,
  showLanguageMenu: true,
  showSignUp: true,
  showLogin: true,
  signUpLabel: null,
  showFooter: true,
  showUserMenu: true,
  containerClassName: null,
  showNotificationPanel: true,
  showTutorials: true,
  audience: 'widget',
  showSmallFooter: false,
  showLinks: true
};

export default withAudience(Main);
